import { uploadMedia } from './uploadMedia';
import { marketing } from './marketing';
import { users } from './users';
import { author } from './author';
import { banner } from './banner';
import { linkChecker } from './linkChecker';
import { vtb } from './vtb';
import { specialAccess } from './specialAccess';
import { qualitySystem } from './qualitySystem';
import { lms } from './lms';
import { sync } from './sync';

export const navConfig = {
  redactie: [users, author, uploadMedia, linkChecker, specialAccess, marketing, qualitySystem],
  marketing: [marketing, banner, qualitySystem],
  sales: [users, sync, marketing, vtb, specialAccess, qualitySystem, lms],
  administrator: [users, sync, author, uploadMedia, linkChecker, marketing, banner, vtb, specialAccess, qualitySystem, lms]
};
