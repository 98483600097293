export const PATH_DASHBOARD = {
  user: {
    list: '/user/list',
    edit: (name) => `/user/${name}/edit`,
    userDetail: (id) => `/user/${id}`
  },
  author: {
    list: '/author/list',
    detail: (id) => `/author/${id}`,
    create: '/author/create'
  },
  marketing: {
    trials: '/marketing/trials',
    trialsAddSingle: '/marketing/trials/add/single',
    trialsAddBatch: '/marketing/trials/add/batch',
    trialsDetail: (id) => `/marketing/trials/${id}`
  },
  uploadMedia: {
    list: '/upload-media/list',
    create: '/upload-media/create'
  },
  banner: {
    list: '/banner/list',
    create: '/banner/create',
    edit: (id) => `/banner/${id}/edit`
  },
  listChecker: {
    list: '/link-checker/list'
  },
  vtb: { overview: '/vtb/event/list' },
  qualitySystem: {
    overview: '/quality-system/overview',
    overviewProfession: (professionId) => `/quality-system/overview/${professionId}`,
    courseDetail: (courseId) => `/quality-system/course-detail/${courseId}`,
    questionDetail: (courseId, questionId) => `/quality-system/question-detail/${courseId}/${questionId}`
  },
  lms: {
    overview: '/lms',
    organisations: (orgId) => `/lms/organisations/${orgId}`
  },
  sync: {
    overview: '/sync'
  }
};

export const PATH_SPECIAL_ACCESS = {
  root: '/special-access',
  inviteUser: '/special-access/invite-user',
  giveDirectAccess: '/special-access/give-direct-access'
};
